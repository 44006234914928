var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
    },
    [
      _c(
        "el-card",
        { attrs: { shadow: "always" } },
        [
          _vm.showadd != -1
            ? _c(
                "el-button",
                {
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.addBtn },
                },
                [_vm._v("新增")]
              )
            : _vm._e(),
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.tableData,
                "row-key": "id",
                border: "",
                "tree-props": _vm.tabDefaultProps,
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "text", label: "名称", width: "180" },
              }),
              _c("el-table-column", {
                attrs: { prop: "icon", label: "图标", width: "180" },
              }),
              _c("el-table-column", {
                attrs: { prop: "type", label: "类型" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.type == 1
                          ? _c("el-tag", { attrs: { type: "success" } }, [
                              _vm._v("按钮"),
                            ])
                          : _c("el-tag", [_vm._v("菜单")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", { attrs: { prop: "path", label: "地址" } }),
              _c("el-table-column", {
                attrs: { prop: "permission", label: "权限" },
              }),
              _c("el-table-column", {
                attrs: { prop: "order", label: "排序" },
              }),
              _c("el-table-column", {
                attrs: { prop: "createTime", label: "创建时间" },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.showupdate != -1
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  size: "mini",
                                  dialogFormVisible: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEdit(scope.row)
                                  },
                                },
                              },
                              [_vm._v("修改")]
                            )
                          : _vm._e(),
                        _vm.showdelete != -1
                          ? _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "danger" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDelete(scope.row)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "astrict",
          attrs: { title: "编辑菜单或按钮", visible: _vm.dialogFormVisible2 },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible2 = $event
            },
            close: _vm.dialogClose,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ediEform",
              attrs: { model: _vm.ediEform, rules: _vm.rules },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "类型\t",
                    prop: "type",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "0", border: "", disabled: "" },
                          on: { change: _vm.handleCheckAllChange },
                          model: {
                            value: _vm.ediEform.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.ediEform, "type", $$v)
                            },
                            expression: "ediEform.type",
                          },
                        },
                        [_vm._v("菜单")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "1", border: "", disabled: "" },
                          on: { change: _vm.handleCheckAllChange },
                          model: {
                            value: _vm.ediEform.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.ediEform, "type", $$v)
                            },
                            expression: "ediEform.type",
                          },
                        },
                        [_vm._v("按钮")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "名称",
                    prop: "menuName",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入内容" },
                    model: {
                      value: _vm.ediEform.menuName,
                      callback: function ($$v) {
                        _vm.$set(_vm.ediEform, "menuName", $$v)
                      },
                      expression: "ediEform.menuName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: "权限\t", "label-width": _vm.formLabelWidth },
                },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入内容" },
                    model: {
                      value: _vm.ediEform.perms,
                      callback: function ($$v) {
                        _vm.$set(_vm.ediEform, "perms", $$v)
                      },
                      expression: "ediEform.perms",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: "图标\t", "label-width": _vm.formLabelWidth },
                },
                [
                  _c("el-input", {
                    ref: "inputRef",
                    attrs: {
                      clearable: "",
                      placeholder: "请输入内容",
                      disabled: _vm.disabled,
                    },
                    on: { focus: _vm.focusInput },
                    model: {
                      value: _vm.ediEform.icon,
                      callback: function ($$v) {
                        _vm.$set(_vm.ediEform, "icon", $$v)
                      },
                      expression: "ediEform.icon",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "地址\t",
                    prop: "path",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      placeholder: "请输入内容",
                      disabled: _vm.disabled,
                    },
                    model: {
                      value: _vm.ediEform.path,
                      callback: function ($$v) {
                        _vm.$set(_vm.ediEform, "path", $$v)
                      },
                      expression: "ediEform.path",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: "排序\t", "label-width": _vm.formLabelWidth },
                },
                [
                  _c("el-input-number", {
                    attrs: {
                      "controls-position": "right",
                      min: 1,
                      disabled: _vm.disabled,
                    },
                    model: {
                      value: _vm.ediEform.orderNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.ediEform, "orderNum", $$v)
                      },
                      expression: "ediEform.orderNum",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "上级菜单\t",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-tree", {
                    ref: "tree",
                    attrs: {
                      data: _vm.tableData,
                      "node-key": "id",
                      "default-checked-keys": _vm.ediEform.parentId,
                      "default-expanded-keys": _vm.ediEform.parentId,
                      "highlight-current": "",
                      "check-strictly": "",
                      "show-checkbox": "",
                      accordion: "",
                      props: _vm.defaultProps,
                    },
                    on: {
                      "check-change": _vm.handleClick,
                      "node-click": _vm.nodeClick,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible2 = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function ($event) {
                      return _vm.confirmEdit("ediEform")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "astrict",
          attrs: { title: "新增菜单或按钮", visible: _vm.dialogFormVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { ref: "addform", attrs: { model: _vm.addform, rules: _vm.rules } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "类型 ",
                    prop: "type",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "0", border: "" },
                          on: { change: _vm.handleCheckAllChange },
                          model: {
                            value: _vm.addform.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.addform, "type", $$v)
                            },
                            expression: "addform.type",
                          },
                        },
                        [_vm._v("菜单")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "1", border: "" },
                          on: { change: _vm.handleCheckAllChange },
                          model: {
                            value: _vm.addform.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.addform, "type", $$v)
                            },
                            expression: "addform.type",
                          },
                        },
                        [_vm._v("按钮")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "名称 ",
                    prop: "menuName",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入内容" },
                    model: {
                      value: _vm.addform.menuName,
                      callback: function ($$v) {
                        _vm.$set(_vm.addform, "menuName", $$v)
                      },
                      expression: "addform.menuName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: "权限\t", "label-width": _vm.formLabelWidth },
                },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入内容" },
                    model: {
                      value: _vm.addform.perms,
                      callback: function ($$v) {
                        _vm.$set(_vm.addform, "perms", $$v)
                      },
                      expression: "addform.perms",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { position: "relative" },
                  attrs: { label: "图标\t", "label-width": _vm.formLabelWidth },
                },
                [
                  _c("el-input", {
                    ref: "inputRef1",
                    staticStyle: { "text-align": "right" },
                    attrs: {
                      clearable: "",
                      placeholder: "请输入内容",
                      disabled: _vm.disabled,
                    },
                    on: { focus: _vm.focusInput },
                    model: {
                      value: _vm.addform.icon,
                      callback: function ($$v) {
                        _vm.$set(_vm.addform, "icon", $$v)
                      },
                      expression: "addform.icon",
                    },
                  }),
                  _c("el-button", {
                    staticClass: "iconModify",
                    attrs: { icon: "el-icon-setting", disabled: _vm.disabled },
                    on: {
                      click: function ($event) {
                        _vm.dialogFormVisible3 = true
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "地址\t",
                    prop: "path",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-input", {
                    ref: "inputRef",
                    attrs: {
                      clearable: "",
                      placeholder: "请输入内容",
                      disabled: _vm.disabled,
                    },
                    on: { focus: _vm.focusInput },
                    model: {
                      value: _vm.addform.path,
                      callback: function ($$v) {
                        _vm.$set(_vm.addform, "path", $$v)
                      },
                      expression: "addform.path",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: "排序\t", "label-width": _vm.formLabelWidth },
                },
                [
                  _c("el-input-number", {
                    attrs: {
                      "controls-position": "right",
                      min: 1,
                      disabled: _vm.disabled,
                    },
                    model: {
                      value: _vm.addform.orderNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.addform, "orderNum", $$v)
                      },
                      expression: "addform.orderNum",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "上级菜单",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-tree", {
                    ref: "tree",
                    attrs: {
                      data: _vm.tableData,
                      "node-key": "id",
                      "default-checked-keys": _vm.addform.parentId,
                      "highlight-current": "",
                      "check-strictly": "",
                      "show-checkbox": "",
                      accordion: "",
                      props: _vm.defaultProps,
                    },
                    on: {
                      "check-change": _vm.handleClick,
                      "node-click": _vm.nodeClick,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function ($event) {
                      return _vm.confirmAdd("addform")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "astrict",
          attrs: { title: "选择图标", visible: _vm.dialogFormVisible3 },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible3 = $event
            },
          },
        },
        _vm._l(this.arrdata, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "my-icon",
              on: {
                click: function ($event) {
                  return _vm.iconModify(item)
                },
              },
            },
            [_c("i", { class: item })]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }